export const AppStatusFavourite = 1;
export const AppStatusBlock = 5;
export const AppStatusWin = 6;

export const ContractSignStatus = 3;

export const PaidInvoiceStatus = 2;

/** статус тендера "подача заявок" */
export const LotStatusAppSubmitting = 1;
